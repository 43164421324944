import React, { FC } from 'react';

import Card from '@/newcar/ui/detail/molecule/Card';
import reviewPointWebp from '@/newcar/ui/detail/img/review-point.webp';
import reviewPointPng from '@/newcar/ui/detail/img/review-point.png';
import ratingFive from '@/newcar/ui/detail/img/rating-five.svg';
import ratingFour from '@/newcar/ui/detail/img/rating-four.svg';
import * as styles from '@/newcar/ui/detail/organism/Reviews.module.styl';

const reviewsCardArray = [
  {
    id: 1,
    title: 'しつこい営業がない',
    profile: 'Y.Y.さん(埼玉県/50代)',
    description: '最初から説明も丁寧でわかり易く、手続きもスムーズでした。他社の場合しつこい営業もされましたが、カルモさんではこちらの意見をしっかり聞いていただけたので良かったです。',
    ratingUrl: ratingFive,
  },
  {
    id: 2,
    title: '車がもらえるから安心',
    profile: 'H.M.さん(宮城県/50代後半)',
    description: 'もらえるプランで返さなくていいから、安心して新車に乗れるところがいいです。',
    ratingUrl: ratingFour,
  },
  {
    id: 3,
    title: '接客が丁寧でおすすめしたい',
    profile: 'M.Y.さん(北海道/50代後半)',
    description: 'とてもわかりやすく電話対応もよく親切でした。車の納車も、解りやすく低姿勢で、聞きやすかったです。友人、知人に、おすすめしたいと、思います。',
    ratingUrl: ratingFour,
  },
  {
    id: 4,
    title: '初心者でも安心',
    profile: 'K.W.さん(宮城県/60代前半)',
    description: '初めて申し込みをしたのですが、何も分からないのに親切丁寧な説明やアドバイスをしてくれました。納車時も日程どうり、説明も丁寧でした。',
    ratingUrl: ratingFive,
  },
  {
    id: 5,
    title: 'ネット完結で家に車が届く',
    profile: 'S.T.さん(福岡県/40代前半)',
    description: 'ネットで全てできたところ、自宅まで車を届けてもらえたところ',
    ratingUrl: ratingFive,
  },
  {
    id: 6,
    title: '審査が早く、簡単',
    profile: 'H.E.さん(山形県/50代前半)',
    description: '審査も早く、簡単に契約できて、とても気に入りました。車が月々定額で新車が乗れて、メンテナンスプランが選べるのが良かったです。',
    ratingUrl: ratingFive,
  },
  {
    id: 7,
    title: 'とりあえずで審査OK',
    profile: 'K.M.さん(山梨県/40代後半)',
    description: '新車に乗りたかったので、とりあえず審査してみました。リースできることになって安心しました。全体的に気に入ったし、対応も素晴らしかったです。',
    ratingUrl: ratingFive,
  },
  {
    id: 8,
    title: '電話もメールもOKで助かる',
    profile: 'T.M.さん(千葉県/40代前半)',
    description: '電話のみでなくメールでも連絡をくれたのが、本当に助かりました。電話は、仕事中に着信あっても、内容を忘れてしまったりもするので。スムーズでした。',
    ratingUrl: ratingFive,
  },
  {
    id: 9,
    title: 'ラインOKでレスが早い',
    profile: 'K.N.さん(静岡県/50代前半)',
    description: '担当者の方の対応がとても親切で、ラインで質問すると直ぐに返信をしてくれて安心感があります。',
    ratingUrl: ratingFive,
  },
  {
    id: 10,
    title: '納車が早かった',
    profile: 'S.M.さん(埼玉県/40代後半)',
    description: '車検切れまで納車を間に合わせて頂き、丁寧に対応して貰いました。',
    ratingUrl: ratingFive,
  },
];

const Reviews: FC = () => (
  <section id="reviews" className="container is-hidden-tablet">
    <Card className={`${styles.reviewsContainer}`}>
      <div id="abtest-rating-1-202408">
        <h2 className={`${styles.reviewsHeading} title is-3 has-text-centered is-margin-bottom-3`}>
          <span className={styles.reviewsSubheading}>
            定額カルモくんは
          </span>
          おかげさまで高評価
        </h2>
        <div className={styles.reviewsContent}>
          <picture className="is-block has-text-centered column">
            <source srcSet={reviewPointWebp} type="image/webp" />
            <source srcSet={reviewPointPng} />
            <img src={reviewPointPng} alt="Google口コミ 4.2獲得！" loading="lazy" decoding="async" width="345" height="204" />
          </picture>
          <p className={`is-block is-margin-top-3 is-size-7 ${styles.reviewsNotice}`}>
            ※１）Googleは、Google LLCの商標です。※２）2024年6月18日時点。
          </p>
        </div>
      </div>

      <div id="abtest-rating-2-202408">
        <h3 className={styles.reviewsUserCommentSpeeachBalloon}>喜びの声も続々！</h3>
        <ul className={`${styles.reviewsUserCommentList}`}>
          <li className={`${styles.reviewsUserCommentContainer} ${styles.isFirst}`}>
            <p className={styles.reviewsUserCommentItem}>
              <span className={styles.reviewsUserCommentText}>
                貯金がなくても
              </span>
              <span className={styles.reviewsUserCommentText}>
                乗りたいと思った新車に
              </span>
              <span className={styles.reviewsUserCommentText}>
                乗れるんだって
              </span>
              <span className={styles.reviewsUserCommentText}>
                正直、驚きましたね。
              </span>
              <span className={styles.reviewsUserCommentTextMin}>
                埼玉県 Mさん（20代）大工
              </span>
            </p>
          </li>
          <li className={`${styles.reviewsUserCommentContainer} ${styles.isSecond}`}>
            <p className={`${styles.reviewsUserCommentItem} ${styles.isSecond}`}>
              <span className={styles.reviewsUserCommentText}>
                子供の送り迎えも
              </span>
              <span className={styles.reviewsUserCommentText}>
                面倒だった家計の管理も
              </span>
              <span className={styles.reviewsUserCommentText}>
                グッとラクになりました！
              </span>
              <span className={styles.reviewsUserCommentTextMin}>
                東京都 Oさん（30代）主婦
              </span>
            </p>
          </li>
          <li className={`${styles.reviewsUserCommentContainer} ${styles.isThird}`}>
            <p className={styles.reviewsUserCommentItem}>
              <span className={styles.reviewsUserCommentText}>
                ずっと中古車だったから
              </span>
              <span className={styles.reviewsUserCommentText}>
                車検や税金を気にせず
              </span>
              <span className={styles.reviewsUserCommentText}>
                新車に乗れるのは
              </span>
              <span className={styles.reviewsUserCommentText}>
                かなり魅力的だった。
              </span>
              <span className={styles.reviewsUserCommentTextMin}>
                青森県 Uさん（40代）自営業
              </span>
            </p>
          </li>
          <li className={`${styles.reviewsUserCommentContainer} ${styles.isFourth}`}>
            <p className={`${styles.reviewsUserCommentItem} ${styles.isFourth}`}>
              <span className={styles.reviewsUserCommentText}>
                ローンの審査は落ちたけど
              </span>
              <span className={styles.reviewsUserCommentText}>
                カーリースの審査は
              </span>
              <span className={styles.reviewsUserCommentText}>
                無事通過で念願の新車に
              </span>
              <span className={styles.reviewsUserCommentText}>
                のれて満足です！
              </span>
              <span className={styles.reviewsUserCommentTextMin}>
                神奈川県 Kさん（40代）バス運転手
              </span>
            </p>
          </li>
        </ul>
      </div>
    </Card>

    <div id="abtest-rating-3-202408" className="is-hidden">
      <Card className={`${styles.reviewsContainer}`}>
        <h2 className={`${styles.reviewsHeading} title is-3 has-text-centered is-margin-bottom-3`}>
          喜びの声も続々！
        </h2>
        <div className={styles.reviewsCardSingleContainer}>
          {
            reviewsCardArray.map((content) => (
              <div className={styles.reviewsCardSingleItem} key={content.id}>
                <h3 className={styles.reviewsCardSingleHeading}>
                  {content.title}
                </h3>
                <span className={styles.reviewsCardSingleProfile}>
                  {content.profile}
                </span>
                <span className={styles.reviewsCardSingleRating}>
                  <img src={`${content.ratingUrl}`} alt={content.title} />
                </span>
                <p className={styles.reviewsCardSingleDescription}>
                  {content.description}
                </p>
              </div>
            ))
          }
        </div>
      </Card>
    </div>
  </section>
);

export default Reviews;
